import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@simcase/simcase-js-sdk';
import useStyles from './useStyles';

function MainPreloader() {
  const { isDisplayed } = useSelector((state) => state.mainPreloaderReducer);
  const classes = useStyles();

  if (!isDisplayed) {
    return null;
  }

  return (
    <div className={classes.mainPreloaderContainer}>
      <Loader />
    </div>
  );
}

export default MainPreloader;
