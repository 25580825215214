export default {
  commonTexts: {
    button: {
      save: 'Save',
      cancel: 'Cancel',
      back: 'Back',
    },
    tableProductsHeader: {
      name: 'Name',
      vendorCode: 'Vendor code',
      price: 'Price',
      action: 'Action',
    },
    multiselectProducts: {
      submitButton: 'Add to actions',
      cancelButton: 'Cancel',
      pageText: 'Page',
      emptyProductsList: {
        title: "There isn't such product",
        description: 'Try a different name or clear your search to see all products',
      },
      emptyProductCategoriesList: {
        title: "There isn't such category",
        description: 'Try a different name or clear your search to see all categories',
      },
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Product bundles',
    bundlesAreaSubtitle:
      'You can edit and delete bundles, as well as enable and disable them without deleting',
    pageTitle: 'Product Bundles',
    addLabelAreaTitle: 'Highlight your products',
    addLabelAreaSubtitle:
      'Combine different items to sell them together with a discount. Motivate customers to add more products to their carts and spend more money!',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'A detailed guide on how to use an app. If you need any help or have any problems please don\'t hesitate to <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">contact us</a> directly. We are here to help',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Bundle products',
    iconableBlockDescription:
      'Create bundles that make sense, such as holiday sets or complementary goods combo. Help your customers to find the best addition to their carts and offer the discount for the bundle',
    addNewBundle: 'Create product bundles',
    card: {
      action: 'Actions',
      actionUpdate: 'Edit',
      actionDelete: 'Remove',
    },
    removeBundleModal: {
      label: 'Do you really want to remove this bundle?',
      agreeButton: 'Agree',
      cancelButton: 'Cancel',
    },
  },
  homePage: {
    title: 'Actions',
    addToShopArea: {
      title: 'Add action to the shop',
      subtitle: 'Here you can set the promotion parameters for products.',
      interactiveBlock: {
        title: 'Create action',
        subtitle:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
        buttonText: 'Create action',
      },
    },
    shopActionsArea: {
      title: 'Actions list',
      subtitle:
        'You can edit and delete packages, as well as temporarily disable and enable offers.',
      interactiveBlock: {
        emptyBlockTitle: 'Actions list is empty',
        emptyBlockErrorTitle: 'Failed to load actions list',
        actionButton: {
          title: 'Actions',
          actionsList: {
            edit: 'Edit',
            delete: 'Delete',
          },
        },
      },
    },
    shopRevisionArea: {
      title: 'Tailor the store to your needs',
      subtitle:
        'Sometimes just a discount is not enough: you need to tell the buyer which product to add to the cart.',
      interactiveBlock: {
        title: 'Product recommendations',
        subtitle:
          'Help the client collect all the goods for 1+1=3 and offer him accessories, related products or more expensive analogues!',
        buttonText: 'Create action',
      },
    },
  },
  detailShopActionPage: {
    title: 'Action setup',
    setupShopActionArea: {
      title: 'Action setup',
      subtitle: 'Here you can manage the promotion parameters.',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Enable action',
        },
        section_2: {
          title_1: 'Action',
          parameter: {
            title: 'Parameter',
            option_1: 'Assign discount',
            option_2: 'Set price',
          },
          meaning: {
            title: 'Meaning',
            hint_1: '(100 means free)',
          },
          title_2: 'Description of the promotion for buyers',
          descriptionPlaceholder: 'Enter a description',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Shopping setup',
      subtitle: 'Here you can set the condition for receiving the promotion.',
      interactiveBlock: {
        productsAmount: {
          title: 'Number of goods',
          subtitle:
            'Specify the quantity of goods, upon purchase of which the promotion will be available',
        },
        productsBinding: {
          title: 'Linking products for purchase',
          subtitle:
            'Select the products after the purchase of which the promotion will be available.',
        },
        productCategoriesBinding: {
          title: 'Linking product categories for purchase',
          subtitle:
            'Select the categories from which the products will be included in the promotion.',
        },
      },
    },
    setupDiscountArea: {
      title: 'Discount setup',
      subtitle:
        'Here you can specify the products or categories for which the discount will be active.',
      interactiveBlock: {
        productsBinding: {
          title: 'Linking products to receive a discount',
          subtitle: 'Select the products for which the discount will be active.',
        },
        productCategoriesBinding: {
          title: 'Linking product categories to receive a discount',
          subtitle: 'Select the categories for which the discount will be active.',
        },
      },
    },
    setupDisplayShopActionArea: {
      title: 'Action display setup',
      interactiveBlock: {
        title: 'Choose the type of promotion display',
        buttonTitle: 'Automatically',
      },
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        'If you have any questions or difficulties, please contact us, we will definitely help. If necessary, you can disable the promotion while we resolve the issue.',
    },
  },
};
