import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storeID: null,
  accessToken: null,
  lang: null,
  publicToken: null,
  appState: null,
};

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setApplicationParams(state, action) {
      const { storeID, accessToken, lang, publicToken, appState } =
        action.payload;
      state.storeID = storeID ?? null;
      state.accessToken = accessToken ?? null;
      state.lang = lang ?? null;
      state.publicToken = publicToken ?? null;
      state.appState = appState ?? null;
    },
  },
});

const { reducer, actions } = applicationSlice;
const { setApplicationParams } = actions;

export { setApplicationParams };
export default reducer;
