import initEcwidApp from './initEcwidApp';
import initI18N from './i18n';
import initSentry from './sentry';
import store from '../store';
import { setApplicationParams } from '../store/slices/applicationSlice';

async function performBoots() {
  initSentry();
  initI18N();
  const ecwidPayload = initEcwidApp();

  store.dispatch(setApplicationParams(ecwidPayload));
}
export default performBoots;
