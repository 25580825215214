import React from 'react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ru from '../locales/ru';
import en from '../locales/en';

function initI18N() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: en,
        },
        ru: {
          translation: ru,
        },
      },
      returnedObjectHandler: (key, value) => {
        if ('html' in value) {
          return <div dangerouslySetInnerHTML={{ __html: value.html }} />;
        }
        return value;
      },
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        lookupQuerystring: 'lang',
      },
    });
}

export default initI18N;
