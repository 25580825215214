import React from 'react';
import { Route, Routes } from 'react-router-dom';
import history from './router/history';
import routes, { routesTemplate } from './router/routes';

import HistoryRouter from './components/HistoryRouter';

const BackButtonTemplate = React.lazy(() =>
  import('./templates/BackButtonTemplate/BackButtonTemplate')
);

const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));
const DetailShopActionPage = React.lazy(() =>
  import('./pages/DetailShopActionPage/DetailShopActionPage')
);
const DashboardPage = React.lazy(() => import('./pages/DashboardPage/DashboardPage'));

const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

function App() {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          path={routes.home.exactPathname}
          element={<HomePage />}
        />
        <Route
          path={routesTemplate.backButtonTemplate.exactPathname}
          element={<BackButtonTemplate />}
        >
          <Route
            path={routes.detailShopAction.exactPathname}
            element={<DetailShopActionPage />}
          />
        </Route>
        <Route
          path={routes.dashboard.exactPathname}
          element={<DashboardPage />}
        />
        <Route
          path={routes.notFound.exactPathname}
          element={<NotFoundPage />}
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
