import environment from '../constants/environment';

function initEcwidApp() {
  const EcwidApp = window.EcwidApp;
  const payload = {};

  if (EcwidApp) {
    EcwidApp.init({
      app_id: environment.REACT_APP_ECWID_APP_ID,
      autoloadedflag: true,
      autoheight: true,
    });

    const ecwidPaload = EcwidApp.getPayload();
    if (ecwidPaload) {
      const { store_id, access_token, lang, public_token, app_state } =
        ecwidPaload;
      payload.storeID = store_id;
      payload.accessToken = access_token;
      payload.lang = lang;

      if (public_token !== undefined) {
        payload.publicToken = public_token;
      }

      if (app_state !== undefined) {
        payload.appState = app_state;
      }
    }
  }
  return payload;
}

export default initEcwidApp;
