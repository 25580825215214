export default {
  commonTexts: {
    button: {
      save: 'Сохранить',
      cancel: 'Отменить',
      back: 'Назад',
    },
    tableProductsHeader: {
      name: 'Название',
      vendorCode: 'Артикул',
      price: 'Цена',
      action: 'Действие',
    },
    multiselectProducts: {
      submitButton: 'Добавить к акции',
      cancelButton: 'Отмена',
      pageText: 'Страница',
      emptyProductsList: {
        title: 'Нет такого товара',
        description: 'Попробуйте другое название или очистите поиск, чтобы посмотреть все товары',
      },
      emptyProductCategoriesList: {
        title: 'Нет такой категории',
        description:
          'Попробуйте другое название или очистите поиск, чтобы посмотреть все категории',
      },
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Комплекты магазина',
    bundlesAreaSubtitle:
      'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно',
    pageTitle: 'Комплекты',
    addLabelAreaTitle: 'Привлеките внимание к товарам',
    addLabelAreaSubtitle:
      'Объединяйте товары в наборы со скидкой, чтобы Покупатель отправлял в корзину сразу несколько позиций и тратил больше денег в вашем магазине',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'Особенности работы приложения, которые необходимо учитывать при его использовании. Если у вас возникнут вопросы или трудности - <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">свяжитесь с нами</a>, мы обязательно поможем',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
      question2: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Собрать комплект',
    iconableBlockDescription:
      'Создавайте комплекты, объединенные общей идеей: наборы к праздникам или комплементарные товары. Подскажите Покупателю, что дополнит его корзину, и подкрепите предложение заманчивой скидкой',
    addNewBundle: 'Добавить комплект',
    card: {
      action: 'Действия',
      actionUpdate: 'Редактировать',
      actionDelete: 'Удалить',
    },
    removeBundleModal: {
      label: 'Вы действительно хотите удалить комплект?',
      agreeButton: 'Да',
      cancelButton: 'Нет',
    },
  },
  homePage: {
    title: 'Акции',
    addToShopArea: {
      title: 'Добавить акцию в магазин',
      subtitle: 'Здесь вы можете настроить параметры акции на товары.',
      interactiveBlock: {
        title: 'Создать акцию',
        subtitle:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
        buttonText: 'Создать акцию',
      },
    },
    shopActionsArea: {
      title: 'Список акций',
      subtitle:
        'Вы можете редактировать и удалять комплекты, а также временно отключать предложения и включать обратно.',
      interactiveBlock: {
        emptyBlockTitle: 'Список акций пуст',
        emptyBlockErrorTitle: 'Не удалось загрузить список акций',
        actionButton: {
          title: 'Действия',
          actionsList: {
            edit: 'Редактировать',
            delete: 'Удалить',
          },
        },
      },
    },
    shopRevisionArea: {
      title: 'Доработайте магазин под ваши нужды',
      subtitle:
        'Иногда просто скидки недостаточно: нужно подсказать покупателю, какой товар еще добавить в корзину.',
      interactiveBlock: {
        title: 'Товарные рекомендации',
        subtitle:
          'Помогите клиенту собрать все товары для 1+1=3 и предложите ему аксессуары, сопутствующие товары или аналоги подороже!',
        buttonText: 'Создать акцию',
      },
    },
  },
  detailShopActionPage: {
    title: 'Настройка акции',
    setupShopActionArea: {
      title: 'Настройка акции',
      subtitle: 'Здесь вы можете управлять параметрами акции.',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Включить акцию',
        },
        section_2: {
          title_1: 'Акция',
          parameter: {
            title: 'Параметр',
            option_1: 'Назначить скидку',
            option_2: 'Задать цену',
          },
          meaning: {
            title: 'Значение',
            hint_1: '(100 - бесплатно)',
          },
          title_2: 'Описание акции для покупателей',
          descriptionPlaceholder: 'Введите описание',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Настройка покупок',
      subtitle: 'Здесь вы можете настроить условие получения акции.',
      interactiveBlock: {
        productsAmount: {
          title: 'Количество товаров',
          subtitle: 'Укажите количество товаров, при покупке которого будет доступная акция',
        },
        productsBinding: {
          title: 'Привязка товаров для покупки',
          subtitle: 'Выберите товары, после покупки которых будет доступна акция.',
        },
        productCategoriesBinding: {
          title: 'Привязка категорий товаров для покупки',
          subtitle: 'Выберите категории, товары из которых будут участвовать в акции.',
        },
      },
    },
    setupDiscountArea: {
      title: 'Настройка скидок',
      subtitle: 'Здесь вы можете указать товары или категории, на которые будет активна скидка.',
      interactiveBlock: {
        productsBinding: {
          title: 'Привязка товаров для получения скидки',
          subtitle: 'Выберите товары, на которые будет активна скидка.',
        },
        productCategoriesBinding: {
          title: 'Привязка категорий товаров для получения скидки',
          subtitle: 'Выберите категории, на товары из которых будет активна скидка.',
        },
      },
    },
    setupDisplayShopActionArea: {
      title: 'Настройка показа акции',
      interactiveBlock: {
        title: 'Выберите тип отображения акции',
        buttonTitle: 'Автоматически',
      },
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        'Если у вас возникнут вопросы или трудности — свяжитесь с нами, мы обязательно поможем. При необходимости вы можете отключить акцию, пока мы решаем проблему',
    },
  },
};
