// type Route = {
//     exactPathname: string; pathname to use as path prop in Route components
//     pathname: string; full pathname to use when redirecting to it
//     children: Route[]
// }

const TEMPLATE_NAMES = {
  BACK_BUTTON_TEMPLATE: 'backButtonTemplate',
};

const routeDetailsTemplate = {
  [TEMPLATE_NAMES.BACK_BUTTON_TEMPLATE]: {
    exactPathname: '/back-button-template',
  },
};

const routesDetails = {
  home: {
    exactPathname: '/',
  },
  dashboard: {
    exactPathname: '/dashboard',
  },
  detailShopAction: {
    exactPathname: '/detail-shop-action',
    template: TEMPLATE_NAMES.BACK_BUTTON_TEMPLATE,
  },
  notFound: {
    exactPathname: '*',
  },
};

const routes = {};

function generateChildPathname(childRoutes, parentRoute) {
  Object.entries(childRoutes).forEach(([childRouteName, childRoute]) => {
    const { exactPathname, children } = childRoute;
    const resultChildRoute = { ...childRoute };
    let childExactPathname = exactPathname;
    if (childExactPathname.startsWith('/')) {
      childExactPathname = childExactPathname.slice(1);
    }
    resultChildRoute.exactPathname = childExactPathname;
    resultChildRoute.pathname = `${parentRoute.pathname}/${childExactPathname}`;
    routes[childRouteName] = resultChildRoute;
    if (children && Object.keys(children).length) {
      generateChildPathname(children, resultChildRoute);
    }
  });
}

Object.entries(routesDetails).forEach(([routeName, route]) => {
  const { exactPathname, children, template } = route;
  routes[routeName] = {
    ...routesDetails[routeName],
  };
  let pathname = exactPathname;
  if (template) {
    pathname = `${routeDetailsTemplate[template].exactPathname}${exactPathname}`;
    routes[routeName].exactPathname = exactPathname.slice(1);
  }
  routes[routeName].pathname = pathname;
  if (children && Object.keys(children).length) {
    generateChildPathname(children, routes[routeName]);
  }
});

console.log({ routes, routeDetailsTemplate });
export { routeDetailsTemplate as routesTemplate };
export default routes;
