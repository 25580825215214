import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import environment from '../constants/environment';

function initSentry() {
  const dsn = environment.REACT_APP_SENTRY_DSN ?? null;
  const appEnvironment =
    environment.REACT_APP_APPLICATION_ENVIRONMENT ?? 'unknown environment';

  if (dsn) {
    Sentry.init({
      dsn,
      environment: appEnvironment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}

export default initSentry;
