import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDisplayed: false,
};

const applicationSlice = createSlice({
  name: 'mainPreloaderSlice',
  initialState,
  reducers: {
    enableMainPreloader(state, action) {
      state.isDisplayed = action.payload;
    },
  },
});

const { reducer, actions } = applicationSlice;
const { enableMainPreloader } = actions;

export { enableMainPreloader };
export default reducer;
