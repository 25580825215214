import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  mainPreloaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 9999,
  },
});

export default useStyles;
